import { createApp } from 'vue'
import { createPinia } from "pinia"

import router from './router'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Form, Field, defineRule, configure } from "vee-validate";
/* import * as rules from "@vee-validate/rules"; */
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import {
    localize
} from "@vee-validate/i18n";
import utils from "@/utils/utils.js";
import { createI18n } from 'vue-i18n'
//Configure Axios
axios.defaults.baseURL = process.env.VUE_APP_BACK;
axios.defaults.withCredentials = true
configure({
    generateMessage: localize("es", {
        messages: {
            required: "Este campo es obligatorio",
            email: 'Introduce un email válido',
            min: 'Debe tener mínimo 8 carácteres',
            confirmed: 'No coincide con la contraseña'
        },
    }),
});
/* Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule]);
}); */
//I18N 
const messages = utils.loadLocaleMessages();
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: process.env.VUE_APP_I18N_LOCALE || 'es',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
    messages: messages
});
//Para evitar tener que esta revisando todos los errores contantemente
createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .use(i18n)
    .use(Field, Form)
    .use(Vue3Lottie)
    .use(createPinia())
    .mount('#app')

import { formStore } from '@/stores/forms'
const storeForm = formStore();
axios.interceptors.response.use(
    function (response) {
        if (response.data.status !== null && response.data.status === false) {
            if (response.data.error == null) {

                return alert(messages.es.generic_error)
                //return store.commit('alert/launchAlert', i18n.t('generic_error'));
            } else {
                return Promise.reject(response);
            }

        }
        return response;
    },
    function (error) {
        let error_text = "";
        let url = process.env.VUE_APP_BACK;
        switch (error.response.status) {
            case 422:
                for (let er in error.response.data.validate_errors) {
                    error_text += error.response.data.validate_errors[er].join(",") + ".";
                }
                storeForm.alert.text = error_text;
                storeForm.alert.active = true;
                storeForm.sending = false;
                break;
            case 401:
                url = url.replace("api/", "saml2/ufv/login");
                window.location.replace(url);
                break;
            default:
                storeForm.alert.text = messages.es.generic_error;
                storeForm.alert.active = true;
                storeForm.sending = false
        }
        return Promise.reject(error);
    }
);
