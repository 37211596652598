import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import('@/views/home.vue'),
  },
  
  {
    path: '/proyectos/:slug',
    name: 'projects',
    props: true,
    component: () =>
      import('@/views/projects.vue'),
  },
  {
    path: '/colabora/:slug',
    name: 'colaborations',
    props: true,
    component: () =>
      import('@/views/colaborations.vue'),
  },
  {
    path: '/comunidad-ufv',
    name: 'entriesPage',
    component: () =>
      import('@/views/entries-page.vue'),
  },
 
  {
    path: '/comunidad-ufv/:slug',
    name: 'view-entry-new-event',
    component: () =>
      import('@/views/view-entry-new-event.vue'),
    props: true,
  },
  {
    path: '/comunidad-ufv/noticia-ligera/:cat/:slug',
    name: 'viewNewSmall',
    props: true,
    component: () =>
      import('@/views/view-new-small.vue'),
  },
  {
    path: '/contacto',
    name: 'contact',
    component: () =>
      import('@/views/contact.vue'),
  },
  {
    path: '/gestiones/:slug/:slugGestion?',
    name: 'gestion-page',
    props: true,
    component: () =>
      import('@/views/gestion-page.vue'),
  },
  {
    path: '/equipo',
    name: 'team',
    component: () =>
      import('@/views/team.vue'),
  },
  {
    path: '/politica-de-cookies',
    name: 'cookies',
    component: () =>
      import('@/views/cookies.vue'),
  },
  {
    path: '/equipo/:id',
    name: 'team-member',
    props: true,
    component: () =>
      import('@/views/team-member.vue'),
  },
  {
    path: '/mapa-web',
    name: 'site-map',
    component: () =>
      import('@/views/site-map.vue'),
  },
  {
    path:'/politica-de-privacidad',
    name:'privacy policy',
    component:()=>
    import('@/views/privacy-policy.vue')
  }


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  
  },
  routes
})

export default router
