import { defineStore } from 'pinia'
import axios from 'axios'
export const formStore = defineStore('forms', {
    state: () => (
        {
            alert : {
               active: false,
                text: ''
            },
            sending: false,
            motives: {

            }
        }
    ),
    getters: {
        
    },
    actions: {
        async loadMotives() {
            let data = {
                order: [
                    {
                        name: 'name',
                        direction: 'asc'
                    }
                ],
            };
            let t = this;
            if (this.motives && Object.keys(this.motives).length > 0) {
                return;
            }
            return new Promise((resolve) => {
                var promise = axios.post('motives', data);
                promise.then(function (result) {
                    result.data.data.forEach(element => {
                        t.motives[element.slug] = element
                    });
                    resolve();
                   
                });
            })
        },
        async formContact( data) {
            return new Promise((resolve) => {
                this.sending = true
                axios.post('contact', data).then(response => {
                    this.sending = false
                    resolve(response);
                   
                })
            });
        },
        async formOffer(data) {
            return new Promise((resolve) => {
                this.sending = true
                axios.post('cv/save', data).then(response => {
                    this.sending = false
                    resolve(response);

                })
            });
        },
    }
})