<template lang="html">
  <footer class="footer">
    <div class="container grid-links">
      <div class="grid">
        <p class="title">DESARROLLO DE PERSONAS UFV</p>
        <router-link :to="{ name: 'team' }">Equipo</router-link>
        <router-link :to="{ name: 'contact' }">Contacto</router-link>
      </div>
      <div class="grid" v-if="store.departments">
        <p class="title">MIS GESTIONES</p>
        <template v-for="(departament, key) in store.departments" :key="key">
          <router-link :to="{name:'gestion-page', 
            params: {
              slug: departament.slug,
              name: departament.name}
            }">
            {{departament.name}}
            <span v-if="departament.new" class="dest">N</span>
          </router-link>
        </template>

      </div>
      <div class="grid">
        <p class="title">RESPONSABILIDAD SOCIAL</p>
        <router-link :to="{name :'projects', params: {slug: 'integra'}}">Proyectos</router-link>
        <router-link :to="{name :'colaborations', params: {slug: 'voluntariado-corporativo'}}">Colabora
        </router-link>
      </div>
      <div class="grid">
        <router-link :to="{ name: 'site-map' }">Mapa web</router-link>
        <a href="https://www.ufv.es/aviso-legal/" target="_blank" title="aviso legal">Aviso Legal</a>
        <a href="/politica-de-privacidad" title="Política de privacidad" target="_blank">Política de Privacidad</a>
        <a href="/politica-de-cookies" title="Política de cookies" target="_blank">Política de Cookies</a>

        <p class="rrss-title">Redes sociales</p>
        <p class="rrss-title">
          <a href="https://www.facebook.com/UFVmadrid/" target="_blank" title="facebook">
            <img src="/img/icons/facebook-white.svg" alt="Facebook">
          </a>
          <a href="https://twitter.com/ufvmadrid" target="_blank" title="twitter">
            <img src="/img/icons/twitter-white.svg" alt="Twitter">
          </a>
          <a href="https://www.instagram.com/ufvmadrid/" target="_blank" title="instagram">
            <img src="/img/icons/instagram-white.svg" alt="Instagram">
          </a>
          <a href="https://www.linkedin.com/school/universidad-francisco-de-vitoria-/" target="_blank" title="linkedin">
            <img src="/img/icons/linkedin-white.svg" alt="Linkedin">
          </a>
          <a href="https://www.youtube.com/user/ufvmadrid" target="_blank" title="youtube">
            <img src="/img/icons/youtube-white.svg" alt="Youtube">
          </a>
        </p>

      </div>
    </div>
  </footer>
</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'footer',
    props: [],
    setup() {
      const store = contentStore();

      return {
        store
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "../styles/general.scss";

  .footer {
    border-bottom: 16px solid $blueDark;
    padding: 40px 0;

    .grid-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .grid {
        width: 22%;

        &:last-of-type {
          padding-top: 28px;
        }

        .title {
          font-weight: bold;
          font-size: 16px;
          color: $blueDark;
          margin-bottom: 10px;
        }

        a {
          text-decoration: none;
          color: $blue;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          img {
            max-width: 25px;
            margin-left: 10px;
          }

        }

        .rrss-title {

          text-decoration: none;
          color: $blue;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          a {
            margin-right: 8px;
            margin-top: 5px;
            display: flex;
            background: $blue;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;

            img {
        
              height: 16px;
              margin-left: 0px;
            }


            i {
              color: white;
              background: $blue;
              font-size: 17px;
              width: 20px;
              height: 20px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              padding: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width:780px) {
    .footer .grid-links .grid {
      width: 48.7%;
      margin-right: 1.3%;
      margin-right: 1.3%;
      padding-top: 0 !important;
    }
  }

  @media screen and (max-width:650px) {
    .footer .grid-links .grid a {
      text-align: center;
    }

    .footer .grid-links .grid {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
</style>