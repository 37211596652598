<template lang="html">
  <header class="header">
    <section class="top-header">
      <div class="container-contact d-flex">
        <a class="button-intranet" href="https://universidadfv.sharepoint.com/sites/IntranetUFV" target="_blank">
          <img src="/img/intranet.svg" alt="Intranet">
          <span>Intranet</span>
        </a>
        <router-link :to="{ name: 'contact' }">Contacto</router-link>
        <a :href="closeUrl" class="button-close d-flex">
          <img src="/img/cerrar.svg" alt="Cerrar sesión">
          <span>Cerrar</span>
        </a>
      </div>
      <div class="container d-flex jcsb aic menu">
        <router-link :to="{ name: 'home' }" class="logo">
          <img src="/img/logo_white.png" alt="UFV logo">
        </router-link>
        <ul class="list-menu d-flex jcfe aic" :showMenuResponsive="showMenuResponsive">
          <li v-if="departmentSubmenu.length > 0" :showSubMenu="showMenuGestion" :class="[$route.name == 'gestion-page' ? 'router-link-active item-dropdown' : 'item-dropdown']">
            <span @click="toggleShowMenuGestion">MIS GESTIONES</span>
            <ul class="sub-menu d-flex jcc aic">
              <div class="d-flex jcc aic">
                <template v-for="(departament, key) in departmentSubmenu" :key="key">
                  <li>
                    <router-link :to="{
                      name: 'gestion-page',
                      params: {
                        slug: departament.slug,
                        name: departament.name
                      }
                    }">
                      {{ departament.name }}
                      <span v-if="departament.new" class="dest">N</span>
                    </router-link>
                  </li>
                </template>
              </div>
            </ul>
          </li>
          <template v-for="(departament, key) in departmentMainMenu" :key="key">
            <li>
              <router-link :to="{
                                name: 'gestion-page',
                                params: {
                                  slug: departament.slug,
                                  name: departament.name
                                }
                              }">
                {{ departament.name }}
                <span v-if="departament.new" class="dest">N</span>
              </router-link>
            </li>
          </template>
          <li :showSubMenu="showMenuRS" :class="[$route.name == 'projects' ? 'router-link-active item-dropdown item-responsability' : 'item-dropdown item-responsability']">
            <span @click="toggleShowMenuRS">Responsabilidad Social</span>
            <ul class="sub-menu">
              <div class="container d-flex jcc aic menu-responsability">
                <li>
                  <router-link :to="{ name: 'colaborations', params: { slug: 'voluntariado-corporativo' } }">Colabora
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'projects', params: { slug: 'altius' } }">Proyectos</router-link>
                </li>


              </div>
            </ul>
          </li>
          <li>
            <router-link :class="[$route.name == 'team-member' ? 'router-link-active' : '']" :to="{ name: 'team' }">
              Equipo</router-link>
          </li>
        </ul>
        <div class="hamburger" @click="toggleShowSubMenuResponsive" :showMenuResponsive="showMenuResponsive">
          <svg viewBox="0 0 64 48">
            <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
            <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
            <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
          </svg>
          <!-- <i class="fa fa-bars"></i> -->
        </div>
      </div>
    </section>
    <!-- <a class="menulogo" @click="toggleShowSubMenu">Button toggle menu</a>
    <nav :showMenu="showMenu">nav show</nav> -->
  </header>
</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'header',
    props: [],
    data() {
      return {
        showMenuGestion: false,
        showMenuRS: false,
        showMenuResponsive: false,
      }
    },
    setup() {
      const store = contentStore();
      store.loadDepartments()
      return {
        store
      }
    },
    methods: {
      toggleShowSubMenuResponsive: function () {
        this.showMenuResponsive = !this.showMenuResponsive;
        if (this.showMenu == true) {
          this.showMenu = false
        }
      },
      toggleShowMenuGestion: function () {
        this.showMenuRS = false;
        this.showMenuGestion = !this.showMenuGestion;
      },
      toggleShowMenuRS: function () {
        this.showMenuGestion = false;
        this.showMenuRS = !this.showMenuRS;
      },

    },
    computed: {
      departmentMainMenu() {
        let mainMenu = []
        Object.values(this.store.departments).forEach(d => {
          if (d.position == 1) {
            mainMenu.push(d)
          }
        })
        return mainMenu
      },
      departmentSubmenu() {
        let subMenu = []
        Object.values(this.store.departments).forEach(d => {
          if (d.position == 2) {
            subMenu.push(d)
          }
        })
        return subMenu
      },
      closeUrl(){
        let url = process.env.VUE_APP_BACK;
        return url.replace("api", "saml2/ufv/logout");
      }
    },
    watch: {
      "$route.path"() {
        this.showMenuGestion = false;
        this.showMenuRS = false;
        this.showMenuResponsive = false
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "../styles/general.scss";

  nav {
    display: none;
  }

  nav[showmenu="true"] {
    display: block;
  }

  header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    .top-header {
      background: #12182ed4;
      position: relative;

      .menu { 
        padding: 10px 0;
      }

      .logo {
        max-width: 150px;

        img {
          width: 100%;
        }
      }

      .hamburger {
        display: none;
      }

      .list-menu {
        /* width: calc(100% - 205px); */
        width: calc(100% - 150px);
        display: flex;
        justify-content: flex-end;

        li {
          color: white;
          cursor: pointer;
          margin-left: 10px;
          padding-right: 15px;
          list-style-type: none;
          max-width: calc(100% / 6);

          a,
          span {
            color: white;
            text-decoration: none;
            font-size: 14px;
            position: relative;
            display: flex;
            text-align: center;

            &::before {
              content: "";
              display: inline-block;
              width: 0;
              height: 1px;
              background: white;
              position: absolute;
              bottom: -7px;
              left: 0;
              transition: all 0.6s ease-in-out;
            }

            &.dest {
              background: $orange;
              padding: 5px;
              font-size: 12px;
              border-radius: 50%;
              line-height: 0;
              margin: 0 auto;
              justify-content: center;
              width: 15px;
              height: 15px;
              text-align: center;

              &:before {
                display: none;
              }
            }

            &.router-link-active {
              &::before {
                width: 60%;
                transition: all 0.6s ease-in-out;
              }
            }

          }

          a:hover {
            &::before {
              width: 60%;
              transition: all 0.6s ease-in-out;
            }
          }

          &.item-dropdown {
            span {
              position: relative;

              &:after {
                content: "";
                display: inline-block;
                background-image: url(/img/icons/arrow-down-white-small.svg);
                background-repeat: no-repeat;
                background-position: right top;
                background-size: 15px;
                position: absolute;
                width: 17px;
                height: 17px;
                right: -20px;
                top: -5px;
                opacity: 0;
                transition: all 0.6s ease-in-out;
              }



              &.dest {

                &:after {
                  display: none;
                }
              }
            }

            &.router-link-active {
              span::before {
                width: 60%;
                transition: all 0.6s ease-in-out;
              }
            }

            &:hover {
              span {
                position: relative;

                &:after {
                  transition: all 0.6s ease-in-out;
                  top: -3px;
                  opacity: 1;
                }

                &::before {
                  width: 60%;
                  transition: all 0.6s ease-in-out;
                }
              }

            }


          }

          &[showsubmenu="true"] {
            span {
              position: relative;

              &:after {
                opacity: 1;
                transition: all 0.6s ease-in-out;
                transform: rotate(-180deg);
                top: -5px;
              }
            }

            .sub-menu {
              opacity: 1;
              display: flex;
              transition: all 0.6s ease-in-out;
              cursor: initial;

              .menu-responsability {
                justify-content: flex-end;
                padding-right: 220px;
              }

              /* transform: translateY(0%); */
            }
          }


          .sub-menu {
            position: absolute;
            top: 100%;
            width: 100%;
            right: 0;
            left: 0;
            background: #12182ead;
            padding: 20px;
            opacity: 0;
            display: none;
            transition: all 0.6s ease-in-out;
            padding-right: 20px;
            padding-left: 20px;

            /* transform: translateY(-100%); */
            a {
              font-size: 16px;
              text-transform: inherit;

              &:hover {
                &:before {
                  display: none;
                }
              }

              &.router-link-active {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .button-close {
    background: none;
    border: none;
    cursor: pointer;
    max-width: 25px;
    width: 100%;
    margin-left: 40px;
    align-items: center;
  }

  header .top-header .container-contact {
    background-color: #2f5496; 
    color: white;
    justify-content: flex-end; 
    align-items: center;
    padding: 10px 70px;

    a {
      color: #ffffffe6;
      text-decoration:none;
      &:first-of-type {
        margin-right: 50px;
      }
    }

    .button-intranet {
        display: flex;
        justify-content: center;
        align-items: center;
      span {
        margin-left: 5px;
        color: #ffffffe6;
      }
    }

    .button-close span {
      position: absolute; 
      right: 20px;
      background-color: transparent;
      color: #ffffffe6;
      font-size: 13px;
      padding: 0;
      min-width: -webkit-max-content;
      min-width: -moz-max-content;
      min-width: max-content;
      text-align: center;
      margin: 0 auto;
    }

  }

  @media screen and (max-width:1417px) {
    header .top-header .logo {
      max-width: 140px;
    }

    header .top-header .list-menu {
      width: calc(100% - 140px);
      padding-left: 10px;
      justify-content: space-between;
    }

    header .top-header .list-menu li {
      padding-right: 0;
    }
  }


  @media screen and (max-width:1270px) {
    header .top-header .container-contact {
      padding: 10px 70px;
      a {
        font-size: 14px;
      }
    }

    header .top-header .list-menu {
      padding-left: 0;
      width: 100%;
      position: fixed;
      height: 100vh;
      background: $blue;
      top: 0px;
      right: 0;
      left: 0;
      bottom: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: -1000;
      clip-path: circle(100px at 100% -30%);
      -webkit-clip-path: circle(100px at 100% -30%);
      transition: all 1s ease-out;

      &[showmenuresponsive="true"] {
        clip-path: circle(1200px at 100% -10%);
        -webkit-clip-path: circle(1400px at 100% 32%);
        z-index: 9;
      }
    }

    header .top-header .list-menu li[showsubmenu=true] .sub-menu .menu-responsability {
      padding-right: 0;
    }

    header .top-header .list-menu li {
      margin-left: 0;
      text-align: center;
      margin-bottom: 15px;
      padding-right: 0;

    }

    header .top-header .list-menu li.item-dropdown:hover span[data-v-29e8c3c6]::before {
      width: 60%;
      transition: all 0.6s ease-in-out;
      margin: 0 auto;
      text-align: center;
      right: 0;
    }

    header .top-header .list-menu li .sub-menu {
      position: initial;
    }

    header .top-header .list-menu li[showsubmenu=true] .sub-menu {
      background: transparent;

      .d-flex {
        flex-direction: column;
      }
    }

    header .top-header {

      position: fixed;
      right: 0;
      left: 0;
      width: 100%;
      top: 0;
    }

    header .top-header .hamburger {
      display: block;
      z-index: 9;

      svg {
        width: 64px;
        height: 48px;
        top: -6px;
        left: -14px;
        stroke: white;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        display: block;


        path {
          transition: stroke-dasharray var(--duration, .85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, .85s) var(--easing, ease) var(--delay, 0s);
          stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
          stroke-dashoffset: var(--offset, 126px);
          transform: translateZ(0);

          &:nth-child(2) {
            --duration: .7s;
            --easing: ease-in;
            --offset: 100px;
            --array-2: 74px;
          }

          &:nth-child(3) {
            --offset: 133px;
            --array-2: 107px;
          }
        }
      }

      &[showmenuresponsive="true"] {
        svg {
          path {
            --offset: 57px;

            &:nth-child(1),
            &:nth-child(3) {
              --delay: .15s;
              --easing: cubic-bezier(.2, .4, .2, 1.1);
            }

            &:nth-child(2) {
              --duration: .4s;
              --offset: 2px;
              --array-1: 1px;
            }

            &:nth-child(3) {
              --offset: 58px;
            }
          }
        }
      }

      .fa {
        color: white;
        font-size: 18px;
      }
    }

    header .top-header .logo {
      z-index: 10;
    }

  }
</style>